@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

/* Login Page*/
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: #EAF1F3;
    font-family: 'Open Sans', sans-serif;
}

body,
html {
    margin: 0;
    padding: 0;
}

.page {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cover {
    background-color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    box-shadow:
        0 3px 3px -2px rgb(0, 0, 0, 0.2),
        0 3px 4px 0 rgb(0, 0, 0, 0.14),
        0 1px 8px 0 rgb(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 475px;
    padding: 60px;
}

.login_input {
    position: relative;
    height: 60px;
}

.login_input input {
    width: 100%;
    padding: 2px;
    border: none;
    border-bottom: 0.5px solid #777777;
    outline: none;
    transition: 0.3s ease;
    background-color: transparent;
    font-size: 18px;
}

.login_input label {
    position: absolute;
    left: 5px;
    top: -5px;
    transition: 0.3s ease;
    z-index: -1;
    color: rgb(141, 141, 141);
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.login_input input:focus {
    border-bottom: 2px solid #2596BE;
    transition: 0.3s ease;
}

.login_input input:focus+label,
.login_input input:valid+label {
    top: -20px;
    left: 0px;
    font-size: 12px;
    transition: 0.3s ease;
    color: #2596BE;
}

.cover img {
    width: 200px;
    align-self: center;
    padding-bottom: 40px;
}

.cover h3 {
    padding-bottom: 15px;
}

.cover p {
    padding-bottom: 40px;
    color: #777777;
}

.error-msg {
    color: #ff0000;
    font-size: 14px;
    margin-top: 10px;
    padding: 8px;
    background-color: #ffe1e1;
    border: 1px solid #ff0000;
    border-radius: 5px;
    transition: 0.25s ease;
    text-align: center !important;
}

/* Error Page */
.errorPage {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 500px;
    width: 70vw;
}

.text-error {
    color: #2596BE;
    text-shadow: rgba(59, 192, 195, 0.4) 5px 1px, rgba(59, 192, 195, 0.3) 10px 3px;
    font-size: 7.5rem;
    line-height: 5.625rem;
    text-align: center;
}

.errorPage h2 {
    font-size: 2rem;
    padding: 40px 0px;
    text-align: center;
}

.errorPage p {
    padding-top: 20px;
    font-size: 1.75rem;
}


/* Header.css */
.header {
    background-color: #2596BE;
    width: 100%;
    padding: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/* Sidebar.css */
.sidebar {
    background-color: #2596BE;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.logo {
    text-align: center;
    flex-shrink: 0;
    padding: 35px 0 15px 0;
}

.logo img {
    height: 45px;
}

.scrollable-content {
    flex-grow: 1;
    overflow-y: auto;
}

.section {
    margin-bottom: 0px;
    padding: 20px 0px 0px 20px;
}

.section p {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 10px;
    padding-bottom: 5px;
}

.section ul {
    list-style: none;
    padding-bottom: 15px;
}

.sidebar .section p,
.sidebar .section ul,
.sidebar .section li,
.sidebar .section a {
    color: white;
    transition: 0.25s all ease-in-out;
}

li {
    padding: 10px 0;
    transition: background-color 0.3s;
    font-size: 14px;
}

li:hover {
    background-color: #2596BE;
}

a {
    text-decoration: none;
}

.accordian {
    max-height: 0;
    overflow: hidden;
    transition: 0.35s all ease-in-out;
    padding-left: 30px;
    font-size: 11px;
}

.accordian.active {
    max-height: 250px;
    padding-left: 15px;
    font-size: 11px;
}

li svg {
    margin-bottom: -3px;
}

.customicon {
    height: 20px !important;
    width: 20px !important;
    margin-top: 1.5px;
}

li span,
li a {
    padding-left: 10px;
}

.clickable {
    cursor: pointer;
}


/* Dashboard Page*/
.dashboard {
    height: 100%;
    width: 100%;
    position: fixed;
    margin: 0;
}

.dashboard .row1 {
    height: 100%;
    width: 100%;
    display: flex;
}

.dashboard .row1 .col1 {
    height: 100%;
    width: 240px;
    background-color: #2596BE;
}

.dashboard .row1 .col1 .sidebar {
    height: 100%;
}

.dashboard .row1 .col2 {
    width: 100%;
}

.dashboard .row1 .col2 .dataRow {
    height: 100px;
    width: 100%;
    background-color: #2596be;
}

.dashboard .row1 .col2 .row2 {
    padding: 0px 45px 0px 45px;
    overflow-y: scroll;
    max-height: calc(100% - 100px);
}

.dashboard .row1 .col2 .row2 h1 {
    padding: 20px 0px 20px;
    width: 100%;
    font-family: 'Open Sans';
    font-size: 18px;
    font-weight: 500;
}

.savebtn {
    width: 100%;
    text-align: center;
}

.savebtn button {
    width: 100px;
    background-color: #1976d2;
    -webkit-text-fill-color: white;
}

.savebtn button:hover {
    background-color: #1976d2;
}

.sbmtRequest {
    width: 100%;
    text-align: center;
}

.sbmtRequest button {
    width: 100px;
    background-color: #1976d2;
    -webkit-text-fill-color: white;
}

.sbmtRequest button:hover {
    background-color: #1976d2;
}

.updatebtn {
    width: 100%;
}

.updatebtn button {
    width: 150px;
    background-color: #1976d2;
    -webkit-text-fill-color: white;
}

.updatebtn button:hover {
    background-color: #1976d2;
}

.rejectbtn {
    width: 100%;
}

.rejectbtn button {
    width: 100px;
    background-color: red;
    -webkit-text-fill-color: white;
}

.rejectbtn button:hover {
    background-color: red;
}

.spacer {
    height: 60px;
}

.cards h2 {
    font-family: 'Open Sans';
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    color: black;
    padding: 10px 0px 0px 0;
}

.cards h4 {
    font-family: 'Open Sans';
    font-size: 24px;
    width: 100%;
    font-weight: bold;
    color: #00AFA5;
    padding-top: 10px;
    padding-bottom: 10px;
}

.cards .percentage {
    font-family: 'Open Sans';
    font-size: 12px;
    width: 100%;
    display: flex;
    padding-top: 10px;
}

.cards .percentage p {
    font-weight: 100;
    color: black;
    width: 100%;
    padding-left: 10px;
    padding-top: 3px;
}

.cards .percentage .box {
    font-weight: bold;
    width: 100px;
    display: flex;
    color: #1AD598;
    align-items: center;
    border-radius: 10px;
    background-color: rgba(26, 213, 152, 0.3);
}

.cards .dividerparent {
    width: 100%;
}

/* Add New Employee Page */
.dashboard .row1 .col2 .row2 h3 {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: black;
    padding: 25px;
}

.dashboard .row1 .col2 .row2 .label {
    font-size: 13px;
    padding-bottom: 10px;
    color: black;
}

.dashboard .row1 .col2 .row2 .info {
    width: 100%;
    font-family: 'Open Sans';
    font-size: 18px;
    font-weight: 500;
    top: 50px;
    display: flex;
    padding-top: 45px;
}

.dashboard .row1 .col2 .row2 .info h5 {
    width: 100%;
    font-family: 'Open Sans';
    font-size: 18px;
    font-weight: 500;
    align-self: center;
}

/* Pagination */
.row-pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.show-entries {
    flex-grow: 1;
}

.pagination {
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

.col-md-3 {
    font-family: 'Open Sans';
    font-size: 14px;
}

.col-md-2 {
    display: flex;
    font-size: 14px;
}

.custom-pagination-item {
    &:hover {
        .MuiButtonBase-root {
            color: black !important;
            border-radius: 50%;
            width: 30px;
            height: 30px;
        }
    }

    &.selected {
        .MuiButtonBase-root {
            background-color: #2596be !important;
            color: white !important;
        }
    }
}

.col-md-7 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.fpage,
.lpage {
    display: flex;
    align-items: center;
    padding: 0 10px;
}

.fpage button {
    background-color: transparent;
    color: black;
    border: none;
    margin: 0;
    cursor: pointer;
    padding-top: 1.5px;
    transition: background-color 0.3s, color 0.3s;
    font-size: 10px;
    align-content: center;
}

.lpage button {
    background-color: transparent;
    color: black;
    border: none;
    margin: 0;
    cursor: pointer;
    padding-top: 1.5px;
    transition: background-color 0.3s, color 0.3s;
    font-size: 10px;
}

.fpage button:disabled,
.lpage button:disabled {
    color: #acacac;
    cursor: not-allowed;
}

.linkActive {
    font-size: 12px;
    font-weight: bold;
}

.p-0 {
    padding: 0px !important;
}

.cardTitle {
    padding: 0 0 30px 0 !important;
}

hr {
    border: 0 !important;
    border-radius: 50px !important;
}

.searchHeader {
    height: 100%;
    width: calc(100% - 65px);
    display: flex;
    justify-content: flex-end;
}

.label.REQUIRED {
    color: red !important;
}

.TextField.REQUIRED>div::before {
    border-bottom: 1px solid red !important;
}

.TextField.REQUIRED>div::after {
    border-bottom: 2px solid red !important;
}

.errMessage {
    color: red;
    font-size: 12px;
}

.w-100 {
    width: 100%;
}

.itemPaper {
    padding: 30px;
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12) !important;
}

.itemBox {
    margin-bottom: 20px;
}

.custom-pagination-item li:hover {
    background-color: transparent;
}

.slowAppear {
    opacity: 1;
    transition: 1s all ease-out;
}

.slowAppear.off {
    opacity: 0;
}

.tableHeader {
    font-weight: bold !important;
    font-size: 10px !important;
}

.pagePaper {
    width: 100%;
    overflow: "hidden";
    padding: 0px 20px;
    margin: 60px 0 20px 0;
    border-radius: 5px;
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12);
}

.pageComponent {
    width: 100%;
    overflow: "hidden";
    padding: 0px 20px;
    margin: 20px 0 20px 0;
    border-radius: 5px;
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12);
}

.tableButton {
    background-color: #2596BE !important;
    color: white !important;
    font-size: 10px !important;
}

.btn {
    margin: 4px !important;
    font-size: 10px !important;
}

.btn.rjct,
.btn.rjct:hover {
    background-color: #2596BE;
}

.btn.success,
.btn.success:hover {
    background-color: #1dac3a;
}

.btn.rejected,
.btn.rejected:hover {
    background-color: #FF0000;
}

.btn.warning,
.btn.warning:hover {
    background-color: #FFA500;
}

.btn.cancel,
.btn.cancel:hover {
    background-color: #FF0000;
}

.btn.tracker,
.btn.tracker:hover {
    background-color: #ED6C02;
}

.btn.skip,
.btn.skip:hover {
    background-color: #008080;
}

.btn.download,
.btn.download:hover {
    background-color: #2596BE;
    font-size: 12px;
    width: 150px;
    height: 30px;
    min-width: 30px;
    padding: 0;
    /* border-radius: 50%; */
}

.btn.report {
    background-color: #2596BE !important;
    color: white !important;
    font-size: 14px !important;
    border-radius: 25px;
}

.btn.excel,
.btn.excel:hover {
    background-color: #1dac3a;
    font-size: 14px !important;
    border-radius: 25px;
}

.btn.expLetter,
.btn.expLetter:hover {
    background-color: #ED6C02;
    font-size: 12px;
    width: 150px;
    height: 30px;
    min-width: 30px;
    padding: 0;
}

.btn.terminationLetter,
.btn.terminationLetter:hover {
    background-color: #FF0000;
    font-size: 12px;
    width: 150px;
    height: 30px;
    min-width: 30px;
    padding: 0;
}

.floatingPaper {
    position: absolute;
    width: 400px;
    background-color: white;
    border: none;
    box-shadow: none;
    padding: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.position-relative {
    position: relative;
}

.closeButton {
    position: absolute;
    right: -10px;
    top: -10px;
    background-color: red;
    width: 25px;
    height: 25px;
    color: white;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    transition: 0.25s all ease-out;
}

.closeButton:hover {
    background-color: rgb(203, 9, 9);
}

.employeeForm input {
    padding: 4.5px 0px;
}

.employeeForm fieldset {
    border: none;
    border-bottom: 1px solid #949494;
    border-radius: 0px;
    transition: 0.25s all ease-out;
    padding: 0;
}

.division {
    border: 1px solid black;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
}

.centered-heading {
    margin-bottom: 20px;
    font-weight: bold;
}

/* Employee Reports */

.data-grid-filter-toolbar .MuiInputBase-root {
    width: 100%;
    display: flex;
    align-items: center;
}


.data-grid-filter-toolbar .MuiFormControl-root {
    width: 100%;
}

.data-grid-filter-toolbar .employeeForm .MuiFormControl-root {
    flex-grow: 1;
}

.data-grid-filter-toolbar .employeeForm .MuiInputBase-input {
    text-align: center;
}

.data-grid-filter-toolbar .MuiDataGrid-columnHeaders {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
}

.notification-container {
    max-height: 250px;
    overflow-y: auto;
}

.editLWD {
    position: absolute;
    width: 350px;
    background-color: white;
    border: none;
    box-shadow: none;
    padding: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.filter {
    border-radius: 0% !important;
    display: flex;
    align-items: center;
}

.custom-filter-panel {
    padding-top: 6px;
    max-height: 250px;
    overflow-y: auto;
    box-shadow: inset 0px 0px 19px 0px rgba(0, 0, 0, 0.1);
}

.custom-filter-panel .MuiFormControl-root {
    margin: 8px 0;
}

.custom-filter-panel .MuiInputBase-root {
    background: white;
}

.custom-filter-panel .filter-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
}

.custom-filter-panel .filter-buttons button {
    margin: 0 4px;
}

.filter-row {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.filter-row .MuiFormControl-root {
    margin: 0 8px;
}

.filter-row .MuiIconButton-root {
    margin-right: 8px;
}

.filter-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.toolbar .css-1knaqv7-MuiButtonBase-root-MuiButton-root {
    color: white !important;
}

.value>label {
    z-index: 1;
}

.requiredDocs .requiredDocsList {
    list-style: none;
    padding-bottom: 15px;
    max-height: 250px;
    overflow-y: auto;
}

.requiredDocs .requiredDocsItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 0 0;
    font-size: 14px;
    transition: none;
    color: black;
    background-color: transparent;
    word-break: break-word;
}

.requiredDocs .textContainer {
    max-width: calc(100% - 120px);
    display: flex;
    align-items: center;
    word-break: break-word;
    flex-grow: 1;
}

.requiredDocs .requiredDocsItem:hover {
    background-color: transparent;
    color: black;
}

.requiredDocs .doc-list {
    display: flex;
    padding: 10px 0;
    font-size: 14px;
    transition: none;
    color: black;
    background-color: transparent;
}

.requiredDocs .docIcon {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border: 1px solid #ccc;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.requiredDocs .removeButton {
    background-color: red;
    -webkit-text-fill-color: white;
}

.requiredDocs .removeButton:hover {
    background-color: red;
}

.addDocBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    flex-wrap: wrap;
}

.requiredDocs .add-list {
    background-color: #2596BE;
    color: white;
}

.requiredDocs .add-list:hover {
    background-color: #2596BE;
}


/* Modals */
:root {
    --grey-50: #F3F6F9;
    --grey-100: #E5EAF2;
    --grey-200: #DAE2ED;
    --grey-300: #C7D0DD;
    --grey-400: #B0B8C4;
    --grey-500: #9DA8B7;
    --grey-600: #6B7A90;
    --grey-700: #434D5B;
    --grey-800: #303740;
    --grey-900: #1C2025;
}

.modal {
    position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: 0.15s all ease-in-out;
}

.base-Modal-hidden {
    opacity: 0;
    pointer-events: none;
}

.modal-content {
    font-family: 'Open Sans';
    font-weight: 500;
    text-align: start;
    position: relative;
    width: 500px;
    background-color: var(--grey-50);
    border-radius: 8px;
    box-shadow: 0 4px 12px rgb(0 0 0 / 0.2);
    padding: 24px;
    color: var(--grey-900);
    display: flex;
    flex-direction: column;
}

.modal-content .modalBody {
    flex: 1;
    max-height: 600px;
    overflow-y: auto;
}

.modal-content .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-content .name {
    margin: 0;
    line-height: 14px;
    display: flex;
}

.modal-content .close-icon {
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    font-size: 12px;
}

.modal-content .close-icon:focus {
    outline: none;
}

.modal-content .modal-description {
    margin: 0;
    line-height: 12px;
    font-weight: 400;
    color: var(--grey-800);
    margin-bottom: 4px;
}

.modal-content .h4 {
    color: #111111;
    font-weight: bold;
    margin-top: 2px;
}

.modal-content .field {
    color: rgba(85, 85, 85, 0.5);
    font-weight: bold;
    margin-top: 10px;
}

.modal-content .divider {
    height: 1px;
    background-color: #111111;
    margin: 8px 0;
    width: 100%;
    font-weight: bold;
}

.modal-content .division .centered-heading {
    text-align: center;
}

.modal-content .document-item {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.salarycertif {
    width: 200px !important;
}

/* DragNDrop */
.dropzone-container {
    border: 2px dashed #2596be;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    background-color: #f0f4f4;
    transition: border 0.3s ease-in-out;
    position: relative;
}

.dropzone-text {
    margin: 0;
    color: #555;
    font-size: 16px;
}

.dropzone-icon {
    font-size: 48px;
    color: #2596be;
    margin: 10px 0;
}

.uploaded-file {
    display: flex;
    align-items: center;
    color: black;
    border-radius: 4px;
    margin: 2px 0px;
}

.file-name {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.delete-button {
    padding: 8px;
    color: #2596be !important;
}

.uploaded-files-container {
    display: flex;
    flex-wrap: wrap;
}

.drag-drop {
    box-shadow: inset 0px 0px 19px 0px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    padding: 10px;
    max-height: 150px;
    overflow-y: scroll;
}

.css-1knaqv7-MuiButtonBase-root-MuiButton-root {
    color: white !important;
    background-color: #1976d2 !important;
}